import { fetchMutualFundData } from '@/data/API'
import { ZakatHolding } from '../types'
import { getBatchQuotes, getQuote, PseudoIexQuote } from '@/data/quote'
import { chunk } from 'lodash'

const isMutualFund = (
  asset: Partial<ZakatHolding & { assetType?: string; issueType?: string }>,
) => asset.issueType === 'mutual' || asset.assetType === 'mutual fund'

export const enrichHoldings = async (
  rawHoldings: Partial<
    ZakatHolding & { assetType?: string; issueType?: string }
  >[],
) => {
  const holdings = [...rawHoldings]

  const holdingsWithData = holdings.filter(
    (holding) => holding.dataFetch?.finished,
  )

  const newMutualFundSymbols = holdings.reduce(
    (acc, holding, index) => {
      if (isMutualFund(holding) && holding.symbol) {
        acc.push({ symbol: holding.symbol, index })
      }
      return acc
    },
    [] as { symbol: string; index: number }[],
  )

  for (const mutualFund of newMutualFundSymbols) {
    try {
      const mutualFundData = await fetchMutualFundData(mutualFund.symbol)
      const { name, price } = mutualFundData
      if (holdings[mutualFund.index]) {
        holdings[mutualFund.index].name = name ?? undefined
        holdings[mutualFund.index].unitPrice = price ?? undefined
        holdings[mutualFund.index].currency = 'USD'
      }
    } catch (error) {
      holdings[mutualFund.index].dataFetch!.missingFields!.push(
        'name',
        'unitPrice',
        'currency',
      )
      holdings[mutualFund.index].dataFetch!.error = (error as Error).message
      console.error(error)
    } finally {
      holdings[mutualFund.index].dataFetch!.finished = true
    }
  }

  const holdingsSymbols = holdings.reduce(
    (acc, holding) => {
      if (
        holding.symbol &&
        !holding.dataFetch?.finished &&
        !isMutualFund(holding)
      ) {
        const existingHoldingData = holdingsWithData.find(
          (_) => _.symbol === holding.symbol,
        )
        if (existingHoldingData) {
          holding.unitPrice = existingHoldingData.unitPrice
          holding.currency = existingHoldingData.currency
          holding.name = existingHoldingData.name
          holding.dataFetch!.finished = true
        } else {
          acc.push({
            symbol: holding.symbol.toUpperCase(),
            strategy: holding.strategy,
          })
        }
      }
      return acc
    },
    [] as { symbol: string; strategy: string | undefined }[],
  )

  if (holdingsSymbols.length === 1) {
    const holdingIndex = holdings.findIndex((holding) => {
      return (
        holding.symbol?.toUpperCase() === holdingsSymbols[0].symbol &&
        holding.strategy === holdingsSymbols[0].strategy
      )
    })
    let quote: PseudoIexQuote | null
    try {
      const quoteSymbol = holdingsSymbols[0].symbol
      quote = await getQuote(quoteSymbol)
    } catch (error) {
      console.error('here', error)
      holdings[holdingIndex].dataFetch!.error = (error as Error).message
      holdings[holdingIndex].dataFetch!.finished = true
      quote = null
    }
    if (!quote) {
      holdings[holdingIndex].dataFetch?.missingFields!.push(
        'unitPrice',
        'currency',
        'name',
      )
    } else {
      holdings[holdingIndex].unitPrice = quote.latestPrice ?? undefined
      holdings[holdingIndex].currency = quote.currency ?? undefined
      holdings[holdingIndex].name = quote.companyName ?? undefined
    }
    holdings[holdingIndex].dataFetch!.finished = true
  } else if (holdingsSymbols.length > 1) {
    let data: {
      [key: string]:
        | {
            quote?: PseudoIexQuote
          }
        | undefined
    } | null

    const holdingBatches = chunk(holdingsSymbols, 100)
    data = {}
    for (const batch of holdingBatches) {
      try {
        const result = await getBatchQuotes(
          batch.map((holding) => holding.symbol),
        )
        debugger
        data = { ...data, ...result }
      } catch (error) {
        console.error(error)
      }
    }

    for (const holding of holdings) {
      if (Object.keys(data).length > 0) {
        if (holding.symbol && data[holding.symbol]) {
          const quote = data[holding.symbol]!.quote

          if (!quote) {
            holding.dataFetch?.missingFields!.push(
              'unitPrice',
              'currency',
              'name',
            )
            holding.dataFetch!.error = 'Response 404 - Unknown symbol'
          } else {
            holding.unitPrice = quote.latestPrice ?? undefined
            holding.currency = quote.currency ?? undefined
            holding.name = quote.companyName ?? undefined
          }
          holding.dataFetch!.finished = true
        } else {
          holding.dataFetch?.missingFields!.push(
            'unitPrice',
            'currency',
            'name',
          )
          holding.dataFetch!.error = 'Response 404 - Unknown symbol'
          holding.dataFetch!.finished = true
        }
      } else {
        holding.dataFetch?.missingFields!.push('unitPrice', 'currency', 'name')
        holding.dataFetch!.error = 'Response 404 - Unknown symbol'
        holding.dataFetch!.finished = true
      }
    }
  }
  return Promise.resolve(holdings)
}
