import React, { useCallback, useState } from 'react'

import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { ControllerRenderProps } from 'react-hook-form'
import { Card, CardContent } from '@/components/ui/card'
import { useClickOutside } from '@mantine/hooks'

export type InputWithOptionsProps<T> = {
  inputProps: ControllerRenderProps<
    { symbol: string; quantity: number; strategy: 'PASSIVE' | 'ACTIVE' },
    'symbol'
  >
  options: {
    value: T
    title: string
    description?: string
  }[]
  onOptionSelected?: (option: {
    value: T
    title: string
    description?: string
  }) => void
}

export const InputWithOptions = <T extends object>(
  props: InputWithOptionsProps<T>,
) => {
  const { inputProps, options, onOptionSelected } = props

  const [opened, setOpened] = useState(true)
  const ref = useClickOutside(() => setOpened(false))

  const onInputFocus = useCallback(() => {
    setOpened(true)
  }, [])

  return (
    <div className="relative w-full ">
      <Input {...inputProps} onFocus={onInputFocus} />
      {opened && inputProps.value !== '' && (
        <div
          ref={ref}
          className="absolute left-0 top-10 flex-col h-full items-center w-full z-10"
        >
          <Card>
            <CardContent className="space-y-2 px-2 py-2">
              {options.map((option) => (
                <Button
                  variant="ghost"
                  key={`${option.title}-${option.description}`}
                  className="flex-col items-start w-full h-fit "
                  onClick={() => {
                    setOpened(false)
                    onOptionSelected && onOptionSelected(option)
                    inputProps.onChange(option.title)
                  }}
                >
                  <p className="font-medium ">{option.title}</p>
                  <p className="text-sm font-light w-full text-left truncate">
                    {option.description}
                  </p>
                </Button>
              ))}{' '}
            </CardContent>
          </Card>
        </div>
      )}
    </div>
  )
}
